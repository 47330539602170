//searchbox : selector for the input searchbox field
//url : the base url that be used in the ajax call
//type : url + "/" + type is the full url that will be sent to, used to control
//flow of controller function
//
//panelSelector : the id of the panel that stores the html that will be replaced
//id : the parent id of the objects being searched, like a lesson for wifphotos
//ids : array of the list of ids that are live on the page, helps modify controller logic

$(function(){
    //getFreshToken();
    setupAjax();
    $('[data-toggle="tooltip"]').tooltip()

    lazyLoadImages();

    //reload images when tabs are switched
    $('a[data-toggle="tab"]').on('shown.bs.tab',function(e){
        lazyLoadImages();
    });

    setFooterWidth();
    $(window).resize(setFooterWidth);


    initTour();




});


function setFooterWidth(){
    var w=0;
    $('#footer .nav li').each(function() {
        w+=$(this).outerWidth();
    });
    $('#footer .nav').css('width',w);
}



function lazyLoadImages(container=null){
    if(container==null){
        $("img.lazy").lazyload({
            skip_invisible:true,
            threshold:600,
            effect:"fadeIn"
        });
    }
    else{
        $("img.lazy").lazyload({
            skip_invisible:true,
            threshold:600,
            effect:"fadeIn",
            container:$(container)
        });
    }
}

function getPrice(plan){
    return $.ajax({
        url:'/stripe/plan/'+plan,
        dataType:'json'
    });

}


function setupAjax(){
    $.ajaxSetup({
        headers:{'X-CSRF-TOKEN':$('meta[name="csrf-token"]').attr('content')}
    });
}

function getFreshToken(){
    $.ajax({
        url:'/token',
        type:"GET",

    }).success(function(data){
        $('meta[name="csrf-token"]').attr('content',data);

        setupAjax();
    });
}



function search(searchbox,url,type,panelSelector,ids){
    //callback to update the panel
    var update= function (data){
        $(data['panel']).html(data['data']);
        lazyLoadImages();
    }

    if($(searchbox).val()!=""){
        $.ajax({
            url:url+"/"+type,
            type:"POST",
            data:{
                query: $(searchbox).val(),
                ids:ids
            }
        }).success(
        function(data){
            //make sure we are still searching when the data is returned, could
            //be backspacing rapidly!
            if($(searchbox).val()!=""){
                $(panelSelector).html(data);

                lazyLoadImages();

            }
            else{
                original(url,type,panelSelector,ids,update);
            }
        }).error(function(e){
            console.log("invalid token");
            getFreshToken();
            console.log(e);
        });
    }else{
        original(url,type,panelSelector,ids,update);
    }
}


//get the original version of this panel data before the search took place
function original(url,type,panelSelector,ids,callback){

    $.ajax({
        url:url+"/all",  //get everything, special keyword for searching
        type:"POST",
        data:{
            query:type , //the type of view that we should be getting
            ids:ids      //list of ids that help with the type of view
        }
    }).success(
    function(data){
        var payload={
            data:data,
            panel:panelSelector
        };
        callback(payload);
    });
}

//add a id to the hidden input list
function addIdToValue(id,selector){
    //add to hidden input
    a = arrayFromValue(selector);
    a.push(id);
    $(selector).val(a.toString());
}

function removeIdFromValue(id,selector){
    //add to hidden input
    a = arrayFromValue(selector)
    var index = a.indexOf(id);
    if(index > -1){
        a.splice(index,1);
    }
    $(selector).val(a.toString());
}

function arrayFromValue(selector){
    //add to hidden input
    if( $(selector).val().split(',')[0]!="") // not empty
        var a = $(selector).val().split(',');
    else{
        var a = new Array(); //start us off
    }
    return a;
}

function prepareSelectedItems(listSelector, inputSelector){


    //check if the input selector already has a value, if so this is a failed
    //form input and we need to reset to what the user had before the fail
    if($(inputSelector).val()){
        setupTable($(inputSelector).val());
    }


    $(listSelector).each(function(){
        addIdToValue($(this).attr("data-id"),inputSelector);
    });
}


//move item based on list of ids by clicking the objects button
function setupTable(ids){

}

function contactEmailAjaxSubmit(){
    console.log('submit form');
    try{
        $.ajax({
            url:"/contact",
            type:"POST",
            dataType:'html',
            data:{
                first_name:$('form#contact input[name="first-name"]').val(),
                last_name:$('form#contact input[name="last-name"]').val(),
                phone_number:$('form#contact input[name="phone-number"]').val(),
                email:$('form#contact input[name="email"]').val(),
                message:$('form#contact textarea[name="message"]').val(),
                button:window.location.search.replace('?button=','') 
            }
        }).success(
        function(data){
            console.log(data);
            $('#contactSection').replaceWith(data);
        }).error(
        function(data){
            if(data["status"] == 422){ //unprocessable entity, invalid input
                //populate error list
                $('#errors').replaceWith(data['responseText']);
            }
        }
        );
    }
    catch(e){
        console.log(e);
    }


}



function requestEmailAjaxSubmit(){
    console.log('submit form');
    try{
        $.ajax({
            url:"/contact",
            type:"POST",
            dataType:'html',
            data:{
                first_name:$('form#contact input[name="first-name"]').val(),
                last_name:$('form#contact input[name="last-name"]').val(),
                phone_number:$('form#contact input[name="phone-number"]').val(),
                email:$('form#contact input[name="email"]').val(),
                message:$('form#contact textarea[name="message"]').val(),
                button:'request-seats'
            }
        }).success(
        function(data){
            console.log(data);
            $('#text-fields').replaceWith("<h4>Thank you, we will be in contect shortly</h4>");
        }).error(
        function(data){
            if(data["status"] == 422){ //unprocessable entity, invalid input
                //populate error list
                $('#errors').replaceWith(data['responseText']);
            }
        }
        );
    }
    catch(e){
        console.log(e);
    }


}







function feedbackEmailAjaxSubmit(){
    console.log('submit form');
    var num_questions = $('input[type="radio"]').length/5;
    var responses = [];

    console.log(responses);
    for(var i = 0; i < num_questions;i++){
        responses.push({"id":i,"response":$('input[name="'+i+'"]:checked').val()});
    }
    console.log(responses);
    try{
        $.ajax({
            url:"/feedback",
            type:"POST",
            dataType:'html',
            data:{
                name:$('input[name="name"]').val(),
                phone_number:$('input[name="phone_number"]').val(),
                email:$('input[name="email"]').val(),
                message:$('textarea[name="message"]').val(),

                lesson:$('input[name="lesson"]').val(),
                contact:document.getElementById("contact").checked ? "on":"off",

                responses:responses

            }
        }).success(
        function(data){
            console.log(data);
            $('#feedback').replaceWith(data);
        }).error(
        function(data){
            if(data["status"] == 422){ //unprocessable entity, invalid input
                //populate error list
                $('#errors').replaceWith(data['responseText']);
                $('html,body').animate({
                    scrollTop:$('#errors').offset().top
                },20);
            }
        }
        );
    }
    catch(e){
        console.log(e);
    }


}





//that = tr row in a table
//removeSelector = table tbody where we remove items from
//addSelector = table tbody where we add items from
//hiddenInput  = field where we store the updates
function listItemMover(that,removeSelector,addSelector,hiddenInput){

    if($(that).hasClass("add")){
        $($(that).find('span')).removeClass("glyphicon-plus");
        $($(that).find('span')).addClass("glyphicon-minus");
        $(that).removeClass("add");
        $(that).addClass("remove");
        $(removeSelector).append( $(that).parents('tr'));

        addIdToValue($(that).attr("data-id"),hiddenInput);
    }
    else{
        $($(that).find('span')).removeClass("glyphicon-minus");
        $($(that).find('span')).addClass("glyphicon-plus");
        $(that).removeClass("remove");
        $(that).addClass("add");
        $(addSelector).append( $(that).parents('tr'));

        //remove from hidden input
        removeIdFromValue($(that).attr("data-id"),hiddenInput);
    }
}



function stripeResponseHandler(status, response) {
    // Grab the form:
    var $form = $('form');

    if (response.error) { // Problem!
        console.log(response.error);

        // Show the errors on the form:
        $form.find('.payment-errors').text(response.error.message);
        $form.find('.submit').prop('disabled', false); // Re-enable submission

    } else { // Token was created!

        // Get the token ID:
        var token = response.id;

        // Insert the token ID into the form so it gets submitted to the server:
        $form.append($('<input type="hidden" name="stripeToken">').val(token));

        // Submit the form:
        $form.get(0).submit();
    }
};



function startTour(){
    console.log("tour should start");

    tour.restart();
    tour.start();


}
var tour ;

function tourStep(i){
    tour.goTo(i);
}

function initTour(){
    tour = new Tour(
        {
            element:"body",
            name:"Tour",
            container:"body",
            debug:true,
            orphan:true,
            steps:[
                {
                    title:"Welcome to the admin tour",
                    content:"This tour will walk you through the various menus, and features of the admin control panel for k4k.\
                    <br /> <br /> You can use the arrow keys on the keyboard to navigate through this tour.\
                    You can also jump to different sections below: <br />\
                    <ul> \
                    <li><a href='javascript:tourStep(1)'>Introduction</a>  </li>\
                    <li><a href='javascript:tourStep(2)'>Navigation</a>  </li>\
                    <li><a href='javascript:tourStep(3)'>Lessons</a>  </li>\
                    <li><a href='javascript:tourStep(30)'>WIF Photos</a>  </li>\
                    <li><a href='javascript:tourStep(57)'>TEKS</a>  </li>\
                    <li><a href='javascript:tourStep(72)'>Resources</a>  </li>\
                    <li><a href='javascript:tourStep(86)'>Vocabulary Words</a>  </li>\
                    <li><a href='javascript:tourStep(92)'>Wildlife</a>  </li>\
                    <li><a href='javascript:tourStep(99)'>Users</a>  </li>\
                    <li><a href='javascript:tourStep(106)'>File-manager, Image-manager,Logs </a>  </li>\
                    <li><a href='javascript:tourStep(107)'>Stats </a>  </li>\
                    </ul>\
                    <br />\
                    If you need help contact Ensemble Group at 361-884-6396 or ccarlisle@ensemblegroup.net\
                    ",

                    backdrop:true,
                    backdropContainer:"body",
                },

                {
                    title:"Introduction",
                    element:"",
                    placement:"bottom",
                    content:"This is the kritters4kids Admin/Editor interface. In this interface you will be able to manage all\
                    the content and users that exist on the platform. Use caution because you are working with live data!.\
                    We will walk through most of the pages in this tour and show you how to create most of the content.\
                    There are some features not included in this tour due to not having the capability to overlay over that content.\
                    Those sections should be fairly straight forward, and if you need help with them you can always contact Ensemble Group.\
                    We will now begin the rest of the tour",
                    backdrop:true,
                },



                {
                    title:"Navigation",
                    element:" .navbar.navbar-static-top ",
                    placement:"bottom",
                    content:"Each entry on the navigation bar corresponds to its label. Simply click on it to be taken to the control panel for that object. We will start with looking at the lessons page.",
                    backdrop:true,
                },

                {
                    path:"/admin/lessons",
                    title:"Lessons",
                    content:"We just clicked the navigation tab labeled 'Lessons'",
                    backdrop:true,
                },
                {
                    path:"/admin/lessons",
                    title:"Panel header bar",
                    element:" #app-layout > div.container > div > div > div > div.panel-heading > div ",
                    placement:"bottom",
                    content:"Each object will have this common interface. With the title of the object we are working with, a search bar, and some actions",
                    backdrop:true,
                },

                {
                    path:"/admin/lessons",
                    title:"Searching",
                    element:" #lesson ",
                    placement:"bottom",
                    content:"This search input field allows you to search the contents\
                    of the lesson. No need to hit enter, the search results will populate\
                    as you type! You can search by title, grade, or the content of the lessons.\
                    Simply type what you want! If you want to return to the non-filtered version,\
                    simply remove your search terms.",
                    backdrop:true,
                },
                {
                    path:"/admin/lessons",
                    title:"Actions",
                    element:" #app-layout > div.container > div > div > div > div.panel-heading > div > div:nth-child(4) > a > button ",
                    placement:"bottom",
                    content:"This is an action that can be done on a lesson. In this case\
                    this button will create a lesson that we can add content to. We will\
                    be clicking this button in the next few steps.",
                    backdrop:true,
                },
                {
                    path:"/admin/lessons",
                    title:"Existing Lessons",
                    element:" #app-layout > div.container > div > div > div > div.panel-body ",
                    placement:"top",
                    content:"This table show the existing lessons and a quick overview of each.",
                    backdrop:true,
                },

                {
                    path:"/admin/lessons",
                    title:"Individual Lesson",
                    element:" #app-layout > div.container > div > div > div > div.panel-body > table > tbody > tr:nth-child(1) ",
                    placement:"bottom",
                    content:"For each lesson you can view the lesson number, title, chapter\
                    (for admin grouping purposes only), grade, public(whether or not the\
                    lesson is the sneak peek), when it was last updated, and a few actions",
                    backdrop:true,
                },
                {
                    path:"/admin/lessons",
                    title:"Individual Lesson Actions",
                    element:" #app-layout > div.container > div > div > div > div.panel-body > table > tbody > tr:nth-child(1) > td:nth-child(7) ",
                    placement:"bottom",
                    content:"The two actions available for lesson is to edit and delete this individual lesson. We are going to click this edit button",
                    backdrop:true,
                },
                {
                    path:"/admin/lessons/create",
                    title:"Lesson create",
                    element:"",
                    content:"We just clicked the create button",
                    backdrop:true,
                },

                {
                    path:"/admin/lessons/create",
                    title:"Lesson edit",
                    element:" #app-layout > div.container > div > div > div ",
                    content:"This page allows you to enter information about the lesson,  we will walk through each field.\
                    Please do not edit any of the fields while we go along because this is a real lesson!",
                    backdrop:true,
                    placement:"top",
                },

                {
                    path:"/admin/lessons/create",
                    title:"Lesson title",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > form > div:nth-child(2)",
                    content:"This is the lesson title field. Note * the next to the fields label. \
                    This means that it is a required field. The title is pretty self explanatory,\
                    it is simply the title for this lesson! Note: The lesson title must be unique for\
                    the grade that the lesson it belongs to, this is for the generation of urls.\
                    The system will alert you if there is a conflict.",
                    backdrop:true,
                    placement:"bottom",
                },

                {
                    path:"/admin/lessons/create",
                    title:"Parent Chapter",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > form > div:nth-child(4)",
                    content:"This is the chapter that the lesson belongs to. Chapters is way of grouping lessons for the editors.\
                    Simply select the chapter (group) that this lesson belongs to. This field does not change \
                    anything on the front end of k4k.",
                    backdrop:true,
                    placement:"bottom",
                },



                {
                    path:"/admin/lessons/create",
                    title:"Parent Lesson",
                    element:"#parent_lesson",
                    content:"The parent lesson field allows you to build up a hierarchy of lessons. This has no \
                    front end functionality, but can help you group things on the back end. May have the front \
                    end capability at some point! ",
                    backdrop:true,
                    placement:"bottom",
                },

                {
                    path:"/admin/lessons/create",
                    title:"Lesson Number",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > form > div:nth-child(7)",
                    content:"This is the lesson of the number, this will show up on the front end.",
                    backdrop:true,
                    placement:"bottom",
                },




                {
                    path:"/admin/lessons/create",
                    title:"Grade Level",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > form > div:nth-child(8)",
                    content:"The grade level field lets the user know which grade level this lesson belongs to.\
                    This has a large influence on the front end as the lessons are grouped by grade first.",
                    backdrop:true,
                    placement:"bottom",
                },

                {
                    path:"/admin/lessons/create",
                    title:"Main Subject",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > form > div:nth-child(9)",
                    content:"The main subject of the lesson. This should the main topic that this lesson will cover.",
                    backdrop:true,
                    placement:"bottom",
                },


                {
                    path:"/admin/lessons/create",
                    title:"Content",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > form > div:nth-child(10)",
                    content:"This is where you will enter the main lesson plan for this lesson. This field has support for formatting.",
                    backdrop:true,
                    placement:"bottom",
                },

                {
                    path:"/admin/lessons/create",
                    title:"Materials",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > form > div:nth-child(11)",
                    content:"This is the field where you will list the materials used in the lesson. Usually the materials are an unordered list ",
                    backdrop:true,
                    placement:"bottom",
                },

                {
                    path:"/admin/lessons/create",
                    title:"Wild life in focus photos",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > form > div:nth-child(12)",
                    content:"This pair of fields allow you to attach wild life images to this lesson.\
                    You will see this format multiple times. Notice the search boxes on each that allow for filtering,\
                    just like the lessons we saw earlier.",
                    backdrop:true,
                    placement:"top",
                },

                {
                    path:"/admin/lessons/create",
                    title:"WIF Photos to select from",
                    element:"#wif-photos > div:nth-child(2) > div > div > div",
                    content:"This bottom panel allows you to search for wild life photos to attach to this lesson.\
                    When you see one you would like to add, simply hit the '+' button.",
                    backdrop:true,
                    placement:"top",
                },

                {
                    path:"/admin/lessons/create",
                    title:"WIF Photos to select from",
                    element:"#wif-photos > div:nth-child(1) > div > div > div",
                    content:"This top panel allows you to remove wild life photos that are already attached to the lesson. Simply click the '-' button to remove them.",
                    backdrop:true,
                    placement:"top",
                },

                {
                    path:"/admin/lessons/create",
                    title:"Resources Panel",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > form > div:nth-child(13)",
                    content:"Just like the wild life photo form, you have two panels that allow you attach resources to this lesson.",
                    backdrop:true,
                    placement:"top",
                },

                {
                    path:"/admin/lessons/create",
                    title:"TEKS Panel",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > form > div:nth-child(14)",
                    content:"Just like the wild life photo form, you have two panels that allow you attach teks to this lesson.",
                    backdrop:true,
                    placement:"top",
                },

                {
                    path:"/admin/lessons/create",
                    title:"Vocabulary Words Panel",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > form > div:nth-child(15)",
                    content:"Just like the wild life photo form, you have two panels that allow you attach vocabulary words to this lesson.",
                    backdrop:true,
                    placement:"top",
                },

                {
                    path:"/admin/lessons/create",
                    title:"Public option",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > form > div:nth-child(16)",
                    content:"This option tells the system whether or not to protect the lesson from public view.\
                    If not protected any one can access this lesson. This option also controls the lesson that \
                    is available for sneak peek. Most of the time this option should be false (unchecked).",
                    backdrop:true,
                    placement:"bottom",
                },

                {
                    path:"/admin/lessons/create",
                    title:"Save as Draft",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > form > div:nth-child(17)",
                    content:"This option tells the system whether or not to publish this lesson on the front end.\
                    You would use this option if you are not done writing the lesson and do not want people to see it.",
                    backdrop:true,
                    placement:"bottom",
                },
                {
                    path:"/admin/lessons/create",
                    title:"Creating the lesson",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > form > div:nth-child(18)",
                    content:"Once you are happy with your edits, you can click the save button. If there are any fields the \
                    system will alert you with a dialogue that tells you what is wrong. It will also highlight any of the problem fields.",
                    backdrop:true,
                    placement:"bottom",
                },
                {
                    path:"/admin/lessons/create",
                    title:"Next tab",
                    element:"",
                    content:"Now that you know about the fields used in a lesson, lets look at how we create the photos, \
                    resources, vocab words, and TEKS. We will start with the wild life photos.",
                    backdrop:true,
                    placement:"bottom",
                },

                {
                    path:"/admin/wifphotos",
                    title:"Wild life Photos",
                    element:"",
                    content:"We just clicked the WIF Photos tab on the navigation bar. This page allows the modification of the\
                    photos that are in the system. Each of these photos is available to be added to any lesson.",
                    backdrop:true,
                    placement:"bottom",
                },


                {
                    path:"/admin/wifphotos",
                    title:"WIF Photos Panel",
                    element:"#app-layout > div.container > div.row > div > div",
                    content:"This panel shows us various details about all the wild life photos.",
                    backdrop:true,
                    placement:"top",
                },
                {
                    path:"/admin/wifphotos",
                    title:"WIF Photos Panel Header",
                    element:"#app-layout > div.container > div.row > div > div > div.panel-heading > div",
                    content:"The top panel has a search bar, layout options and a create button.",
                    backdrop:true,
                    placement:"bottom",
                },

                {
                    path:"/admin/wifphotos",
                    title:"Grid layout",
                    element:"#app-layout > div.container > div.row > div > div > div.panel-heading > div > div.col-md-2 > a:nth-child(1)",
                    content:"The grid option shows just the images in a nice grid layout.",
                    backdrop:true,
                    placement:"bottom",
                    onShow:function(){
                        $('#app-layout > div.container > div.row > div > div > div.panel-heading > div > div.col-md-2 > a:nth-child(1)')[0].click();
                    }
                },

                {
                    path:"/admin/wifphotos",
                    title:"Grid layout",
                    element:"#grid",
                    content:"The nice grid, clicking on a picture would take you to the detail view for that picture.(doing this would stop the tour!) ",
                    backdrop:true,
                    placement:"top",
                },



                {
                    path:"/admin/wifphotos",
                    title:"List layout",
                    element:"#app-layout > div.container > div.row > div > div > div.panel-heading > div > div.col-md-2 > a:nth-child(2)",
                    content:"The list option shows the images with some extra information in a list view.",
                    backdrop:true,
                    placement:"bottom",
                    onShow:function(){
                        $('#app-layout > div.container > div.row > div > div > div.panel-heading > div > div.col-md-2 > a:nth-child(2)')[0].click();
                    }
                },

                {
                    path:"/admin/wifphotos",
                    title:"List layout",
                    element:"#list",
                    content:"The list view shows you a smaller thumbnail of the image, along with the title, category,creation date, update date, and some actions.",
                    backdrop:true,
                    placement:"top",
                },

                {
                    path:"/admin/wifphotos/create",
                    title:"WIF Create",
                    element:"",
                    content:"We just clicked the create button.",
                    backdrop:true,
                    placement:"top",
                },

                {
                    path:"/admin/wifphotos/create",
                    title:"WIF Edit",
                    element:"#app-layout > div.container > div > div",
                    content:"This is the panel that allows us to edit the details of a wild life photo.",
                    backdrop:true,
                    placement:"top",
                },
                {
                    path:"/admin/wifphotos/create",
                    title:"Title",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > form > div:nth-child(2)",
                    content:"This is the title field for the wild life photo. ",
                    backdrop:true,
                    placement:"bottom",
                },
                {
                    path:"/admin/wifphotos/create",
                    title:"File chooser",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > form > div:nth-child(3)",
                    content:"This is the place where you can attach an image to the wild life photo. The image must be first \
                    uploaded to server through the image manager before we can select it here.",
                    backdrop:true,
                    placement:"bottom",
                },

                {
                    path:"/admin/wifphotos/create",
                    title:"Place",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > form > div:nth-child(7)",
                    content:"This is the place that the photo came in for its competition.",
                    backdrop:true,
                    placement:"bottom",
                },

                {
                    path:"/admin/wifphotos/create",
                    title:"Contest",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > form > div:nth-child(8)",
                    content:"This field is for the contest that the photo was submitted into.",
                    backdrop:true,
                    placement:"bottom",
                },

                {
                    path:"/admin/wifphotos/create",
                    title:"Category",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > form > div:nth-child(9)",
                    content:"These two fields allow you to select a category or create a new one that this photo belongs to.\
                    The bottom dropdown allows you to select existing ones. The textbox allows you to enter new ones.",
                    backdrop:true,
                    placement:"bottom",
                },

                {
                    path:"/admin/wifphotos/create",
                    title:"Photo  Number",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > form > div:nth-child(10)",
                    content:"This number usually corresponds to a number from the wild life in focus website or photoshelter.",
                    backdrop:true,
                    placement:"bottom",
                },

                {
                    path:"/admin/wifphotos/create",
                    title:"Photographer",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > form > div:nth-child(11)",
                    content:"This field is for the name of the person that took the image",
                    backdrop:true,
                    placement:"bottom",
                },

                {
                    path:"/admin/wifphotos/create",
                    title:"Land Owner",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > form > div:nth-child(12)",
                    content:"This field is for the person's name that owns the land that the photo was taken on.",
                    backdrop:true,
                    placement:"bottom",
                },

                {
                    path:"/admin/wifphotos/create",
                    title:"Ranch Name",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > form > div:nth-child(13)",
                    content:"This field is for the name of the ranch that the photo was taken on. ",
                    backdrop:true,
                    placement:"bottom",
                },

                {
                    path:"/admin/wifphotos/create",
                    title:"County",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > form > div:nth-child(14)",
                    content:"This field is for the name of the county that the photo was taken within.",
                    backdrop:true,
                    placement:"bottom",
                },


                {
                    path:"/admin/wifphotos/create",
                    title:"Eco-region",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > form > div:nth-child(15)",
                    content:"This field is for the eco-region that the photo was taken in.",
                    backdrop:true,
                    placement:"bottom",
                },

                {
                    path:"/admin/wifphotos/create",
                    title:"Photoshelter link",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > form > div:nth-child(16)",
                    content:"This field should be a url that points to photos individual page within photoshelter.\
                    <br /> <b>Note that we only want the end of the url!</b>",
                    backdrop:true,
                    placement:"bottom",
                },

                {
                    path:"/admin/wifphotos/create",
                    title:"Latitude",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > form > div:nth-child(17)",
                    content:"This is the latitude of where the photo was taken",
                    backdrop:true,
                    placement:"bottom",
                },

                {
                    path:"/admin/wifphotos/create",
                    title:"Latitude",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > form > div:nth-child(18)",
                    content:"This is the longitude of where the photo was taken.",
                    backdrop:true,
                    placement:"bottom",
                },


                {
                    path:"/admin/wifphotos/create",
                    title:"Wild life ",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > form > div:nth-child(19)",
                    content:"These groups of fields allow you to select which animals are in this photo.\
                    Choose animals from the bottom panel and hit the '+' to add them to this photo.\
                    If you want to remove animals, just simply hit the '-' button.\
                    <br /> These animals are added in the Wildlife page",
                    backdrop:true,
                    placement:"bottom",
                },

                {
                    path:"/admin/wifphotos/create",
                    title:"Tags",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > form > div:nth-child(20)",
                    content:"This field is to add extra information that is searchable for this photo.\
                    Making these images easier to find. Simply enter words that apply to this picture,\
                    like 'sunset' or 'flight' and they will be searchable.",
                    backdrop:true,
                    placement:"bottom",
                },


                {
                    path:"/admin/wifphotos/create",
                    title:"Camera Meta Data",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > form > div:nth-child(21)",
                    content:"This field is for the extra information provided in the photo by the camera. \
                    Things such as ISO and focal length. Usually provided as an unordered list.",
                    backdrop:true,
                    placement:"bottom",
                },


                {
                    path:"/admin/wifphotos/create",
                    title:"Create",
                    element:"#app-layout > div:nth-child(2) > div > div > div > div.panel-body > form > div:nth-child(23)",
                    content:"To create the image with the new data you simply need to hit the create button. \
                    (Doing this will change live data if you made any edits during the tour!!!) This ends the tour for wildlife photos.\
                    <br /> Creating TEKS is the next step in the tour, or you can end the tour now.",
                    backdrop:true,
                    placement:"bottom",
                },


                {
                    path:"/admin/teks",
                    title:"TEKS",
                    element:"",
                    content:"We just clicked the TEKS button on the navigation bar. This page is where you will manage all the teks.",
                    backdrop:true,
                    placement:"bottom",
                },

                {
                    path:"/admin/teks",
                    title:"TEKS",
                    element:"#app-layout > div.container > div > div > div",
                    content:"This panel is where you can view, edit, and delete teks.",
                    backdrop:true,
                    placement:"top",
                },

                {
                    path:"/admin/teks",
                    title:"Top panel header",
                    element:"#app-layout > div.container > div > div > div > div.panel-heading",
                    content:"This panel has a handy search box and create button",
                    backdrop:true,
                    placement:"bottom",
                },
                {
                    path:"/admin/teks",
                    title:"Category Selector",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > select",
                    content:"This dropdown allows your to select to only display a certain category, helping reduce the amount of teks displayed.",
                    backdrop:true,
                    placement:"bottom",
                },

                {
                    path:"/admin/teks",
                    title:"TEKS List",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > table",
                    content:"This table shows all the TEKS that fit the current search and category.\
                    It shows you some quick info and the Hierarchy of the TEKS",
                    backdrop:true,
                    placement:"top",
                },

                {
                    path:"/admin/teks/create",
                    title:"TEKS Creation  ",
                    element:"",
                    content:"We just clicked the create button and are now going to run through the steps of creating a TEKS.",
                    backdrop:true,
                    placement:"bottom",
                },

                {
                    path:"/admin/teks/create",
                    title:"TEKS. creation",
                    element:"#app-layout > div.container > div > div > div",
                    content:"This panel is where we will add all the information for the TEKS.",
                    backdrop:true,
                    placement:"top",
                },

                {
                    path:"/admin/teks/create",
                    title:"TEKS. number",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > form > div:nth-child(2)",
                    content:"This form input is for the TEKS. number. ",
                    backdrop:true,
                    placement:"bottom",
                },

                {
                    path:"/admin/teks/create",
                    title:"Grade",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > form > div:nth-child(3)",
                    content:"This is the grade level of the tek. Should be 1,2,3,5,K",
                    backdrop:true,
                    placement:"bottom",
                },

                {
                    path:"/admin/teks/create",
                    title:"Category",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > form > div:nth-child(4)",
                    content:"These two fields allow you to select the category or create a new one that this tek belongs to.",
                    backdrop:true,
                    placement:"bottom",
                },

                {
                    path:"/admin/teks/create",
                    title:"Parent Tek",
                    element:"#parents > div:nth-child(1)",
                    content:"This field allows you to select a parent tek, this is to build the hierarchy of teks.\
                    Note that this field is not accessible until you select or create a category.",
                    backdrop:true,
                    placement:"bottom",
                },

                {
                    path:"/admin/teks/create",
                    title:"Tek Summary",
                    element:"#parents > div:nth-child(2)",
                    content:"You will once again be choosing a tek to associate with this tek.\
                    The tek that you will want to select is the one that contains the summary for this teks siblings.",
                    backdrop:true,
                    placement:"bottom",
                },

                {
                    path:"/admin/teks/create",
                    title:"TEK Content",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > form > div:nth-child(7)",
                    content:"This is where you will enter the content of the tek. ",
                    backdrop:true,
                    placement:"top",
                },

                {
                    path:"/admin/teks/create",
                    title:"Create TEK",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > form > div:nth-child(8)",
                    content:"Once you are done inputting all this teks information you can click the create button. (Please do not do this as part of the tour!)",
                    backdrop:true,
                    placement:"top",
                },

                {
                    path:"/admin/teks/create",
                    title:"Create TEK",
                    element:"",
                    content:"We are now done with the creation of teks. We will be moving onto the resource creation next.",
                    backdrop:true,
                    placement:"top",
                },

                {
                    path:"/admin/resources",
                    title:"Resources",
                    element:"",
                    content:"We just clicked the resources button on the navigation bar. This page will let us manage existing resources and create them.",
                    backdrop:true,
                    placement:"top",
                },

                {
                    path:"/admin/resources",
                    title:"Resources panel",
                    element:"#app-layout > div.container > div > div > div > div.panel-heading",
                    content:"The top panel allows us to search and create new resources",
                    backdrop:true,
                    placement:"bottom",
                },

                {
                    path:"/admin/resources",
                    title:"Resources Listing",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > table",
                    content:"This table shows the list of all the resources available to be attached to lessons.\
                    Each resource can be a different type, either an attachment, link, or embed code.\
                    <br /> Clicking the button under the download column will show you the resource, either on its own page or an external url.",
                    backdrop:true,
                    placement:"top",
                },

                {
                    path:"/admin/resources/create",
                    title:"Resource creation",
                    element:"",
                    content:"We just clicked on the new resource button on the resource page. This new page will be where you create a resource.",
                    backdrop:true,
                    placement:"bottom",
                },

                {
                    path:"/admin/resources/create",
                    title:"Resource creation",
                    element:"#app-layout > div.container > div > div > div > div.panel-body",
                    content:"We just clicked on the new resource button on the resource page. This new page will be where you create a resource.",
                    backdrop:true,
                    placement:"top",
                },

                {
                    path:"/admin/resources/create",
                    title:"Resource Title",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > form > div:nth-child(2)",
                    content:"This field will be the title of the resource, these are displayed on the lesson plan so choose something descriptive!",
                    backdrop:true,
                    placement:"bottom",
                },


                {
                    path:"/admin/resources/create",
                    title:"Resource Description",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > form > div:nth-child(3)",
                    content:"This field will be the description of the resource, as of right now these are not displayed on the front end.",
                    backdrop:true,
                    placement:"bottom",
                },


                {
                    path:"/admin/resources/create",
                    title:"Resource Subject",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > form > div:nth-child(4)",
                    content:"This field is the subject of the resource. These are not displayed on the front end either",
                    backdrop:true,
                    placement:"bottom",
                },

                {
                    path:"/admin/resources/create",
                    title:"Resource file",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > form > div:nth-child(5)",
                    content:"For the next 3 fields you will only fill out a single one.\
                    You can Either choose the file , url or embed code.\
                    The file will most likely be a pdf or word doc.",
                    backdrop:true,
                    placement:"bottom",
                },

                {
                    path:"/admin/resources/create",
                    title:"Resource URL",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > form > div:nth-child(6)",
                    content:"The url will be a link to external resource that you would like to link to from a lesson",
                    backdrop:true,
                    placement:"bottom",
                },

                {
                    path:"/admin/resources/create",
                    title:"Resource embed code",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > form > div:nth-child(7)",
                    content:"This input allows you to paste in an embed code such as a youtube video,\
                    a google map or something else that may be useful to display on the lesson plan",
                    backdrop:true,
                    placement:"bottom",
                },

                {
                    path:"/admin/resources/create",
                    title:"Resource embed code",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > form > div:nth-child(8)",
                    content:"Once we are done entering the fields we can click the create button.\
                    (Please don't click the button as it will interfere with the tour!",
                    backdrop:true,
                    placement:"bottom",
                },

                {
                    path:"/admin/resources/create",
                    title:"Resource embed code",
                    element:"",
                    content:"We are now done with the creation of resources. We will cover vocabulary words next, or you can end the tour.",
                    backdrop:true,
                    placement:"bottom",
                },

                {
                    path:"/admin/vocab_words",
                    title:"Vocabulary Words",
                    element:"",
                    content:"We just clicked the vocab words button on the navigation bar.\
                    This page will allow us to manage all the vocabulary words that can be attached to a lesson.",
                    backdrop:true,
                    placement:"bottom",
                },


                {
                    path:"/admin/vocab_words",
                    title:"Vocabulary Words top panel",
                    element:"#app-layout > div.container > div > div > div > div.panel-heading",
                    content:"This top panel allows searching of existing vocabulary words and creation of new ones.",
                    backdrop:true,
                    placement:"bottom",
                },

                {
                    path:"/admin/vocab_words",
                    title:"Vocabulary Words top panel",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > table",
                    content:"This table lists all the existing vocabulary words. Giving you a short blurb of its definition.\
                    You can click on the definition or the word to view the full thing.",
                    backdrop:true,
                    placement:"top",
                },

                {
                    path:"/admin/vocab_words/create",
                    title:"Vocabulary Words creation",
                    element:"",
                    content:"We just clicked the create button on the previous page.\
                    This new page is where we will be able to create vocabulary words.",
                    backdrop:true,
                    placement:"bottom",
                },

                {
                    path:"/admin/vocab_words/create",
                    title:"Vocabulary Words:word",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > form > div:nth-child(2)",
                    content:"This field is where you enter the word that we will define.",
                    backdrop:true,
                    placement:"bottom",
                },

                {
                    path:"/admin/vocab_words/create",
                    title:"Vocabulary Words: definition",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > form > div:nth-child(3)",
                    content:"This is where you define the word that you entered in the last field.",
                    backdrop:true,
                    placement:"bottom",
                },


                {
                    path:"/admin/vocab_words/create",
                    title:"Vocabulary Words: Creation",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > form > div:nth-child(4)",
                    content:"When you are happy with the definition of the word and are ready to create it,\
                    simply hit this create button! We will next cover the creation of wildlife!\
                    Or you can end the tour if you are only interested in vocabulary words.",
                    backdrop:true,
                    placement:"bottom",
                },


                {
                    path:"/admin/wildlife",
                    title:"Wildlife",
                    element:"",
                    content:"We just clicked the 'wildlife' button on the navigation bar.\
                    This page will let us manage all the different wildlife that can be attached to wild life photos.",
                    backdrop:true,
                    placement:"bottom",
                },


                {
                    path:"/admin/wildlife",
                    title:"Wildlife",
                    element:"#app-layout > div.container > div > div > div > div.panel-heading",
                    content:"This header allows for searching of wildlife and the creation of them.",
                    backdrop:true,
                    placement:"bottom",
                },


                {
                    path:"/admin/wildlife",
                    title:"Wildlife",
                    element:"table",
                    content:"This table lists existing wildlife by its common name and scientific name.",
                    backdrop:true,
                    placement:"top",
                },
                {
                    path:"/admin/wildlife/create",
                    title:"Wildlife",
                    element:"",
                    content:"We just clicked the create button on the previous page.\
                    This page allows us to create new wildlife that can be attached to a lesson",
                    backdrop:true,
                    placement:"bottom",
                },

                {
                    path:"/admin/wildlife/create",
                    title:"Wildlife",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > form > div:nth-child(2)",
                    content:"Simply enter the common name of the animal",
                    backdrop:true,
                    placement:"bottom",
                },

                {
                    path:"/admin/wildlife/create",
                    title:"Wildlife",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > form > div:nth-child(3)",
                    content:"Then enter the scientific name of the animal",
                    backdrop:true,
                    placement:"bottom",
                },

                {
                    path:"/admin/wildlife/create",
                    title:"Wildlife",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > form > div:nth-child(4)",
                    content:"When are done entering the information you can create the wildlife by hitting the create button.\
                    We are now done with the wildlife and will be moving on to the users.",
                    backdrop:true,
                    placement:"bottom",
                },

                {
                    path:"/admin/users",
                    title:"Users",
                    element:"",
                    content:"We just clicked the users button on the navbar. This page allows for the \
                    administration of the users that exist within the system. ",
                    backdrop:true,
                    placement:"bottom",
                },

                {
                    path:"/admin/users",
                    title:"Users",
                    element:"#app-layout > div.container > div > div > div > div.panel-heading",
                    content:"This top panel allows you to search and edit users",
                    backdrop:true,
                    placement:"bottom",
                },

                {
                    path:"/admin/users",
                    title:"Users",
                    element:"table",
                    content:"This table shows the list of users. Each user is grouped by the \
                    school that they belong to. Admins and editors are listed independently. ",
                    backdrop:true,
                    placement:"top",
                },

                {
                    path:"/admin/users",
                    title:"Users",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > table > tbody > tr:nth-child(1)",
                    content:"Any single user shows the name, role,email, when their subscription ends,\
                    the last time they logged in, if they are a school they have a payment button, along with edit,delete and a masquerade field.",
                    backdrop:true,
                    placement:"bottom",
                },

                {
                    path:"/admin/users",
                    title:"Masquerading as another user.",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > table > tbody > tr:nth-child(1) > td:nth-child(9)",
                    content:"Clicking the masquerade button will allow an admin to be logged in as any other user.\
                    This action is logged so don't try anything funny!",
                    backdrop:true,
                    placement:"bottom",
                },

                {
                    path:"/admin/users",
                    title:"Users",
                    element:"",
                    content:"The creation of users is still a bit in the air as the process has not been fully detailed.\
                    More information will be here when the process is further along. You can click the create button \
                    and add users now but the payment features are still a work in progress. \
                    <br /> Do note that when you create a user, you can not give them a password, the user will be \
                    instead emailed and prompted to activate their account. This is a security feature as \
                    no one should ever have access to the plain text of someones password!!!! ",
                    backdrop:true,
                    placement:"bottom",
                },

                {
                    path:"/admin/users",
                    title:"Users",
                    element:"",
                    content:"Since we are still working on features of user creation. ",
                    backdrop:true,
                    placement:"bottom",
                },

                {
                    path:"",
                    title:"Tour support",
                    element:"",
                    content:"This tour can not operate on the file-manager, image-manager, or the logs are they are \
                    an independently developed part of this application. If you need more guidance you can contact \
                    Ensemble Group at 361-884-6396 or ccarlisle@ensemblegroup.net",
                    backdrop:true,
                    placement:"bottom",
                },

                {
                    path:"/admin/statsquery",
                    title:"Stats",
                    element:"",
                    content:"This page allows you to see view stats in more detail than available on google analytics.",
                    backdrop:true,
                    placement:"bottom",
                },

                {
                    path:"",
                    title:"Stats",
                    element:"#pageviews",
                    content:"You start of with a display of all users,grade,lessons for the past month to the current date.\
                    Be sure to check out the scale at the bottom to see how many page views that lesson has.",
                    placement:"top",
                },

                {
                    path:"",
                    title:"Stats",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > div:nth-child(1) > div:nth-child(1)",
                    content:"You can filter based on a certain school, or a certain user. ",
                    placement:"bottom",
                },


                {
                    path:"",
                    title:"Stats",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > div:nth-child(1) > div:nth-child(2)",
                    content:"You can also filter based on the grade or the lesson",
                    placement:"bottom",
                },

                {
                    path:"",
                    title:"Stats",
                    element:"#app-layout > div.container > div > div > div > div.panel-body > div:nth-child(1) > div:nth-child(3)",
                    content:"You can also change the date range for the number of page views, set it as broad or specific as you would like!",
                    placement:"bottom",
                },

                {
                    path:"",
                    title:"End of tour",
                    element:"",
                    content:"That is it for the tour, if you are still needing further assistance,\
                    please contact Ensemble Group at 361-884-6396 or ccarlisle@ensemblegroup.net ",
                    placement:"bottom",
                },

            ],

        }
    );
    tour.init();


}


function validateEmail(email)
{
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        console.log(re.test(email));
        return re.test(email);
}

function appendButton(e){
    $('input[name="option"]').val( $(e).attr('name'));
}

function fixOption(e){
    var min = parseInt($(e.target).attr('min'));
    var value= parseInt($(e.target).val());
    console.log(value,'<',min,e);

    if(isNaN(value)){
        console.log(value,'<',min,e);
        $(e.target).attr('value',min);
        $(e.target).val(min);
        $(e.target).prop(min);
        $(e.target).trigger('change');
    }

    if(value < min){
        $(e.target).attr('value',min);
        $(e.target).val(min);
        $(e.target).prop(min);
        $(e.target).trigger('change');
    }
    recalculateTotal();
}

function recalculateTotal(){
    $('.school').each(function(){
        var total= parseInt($(this).find('.amount').html());
        var extra_books= (parseInt($(this).find('.books').val())-parseInt($(this).find('.books').attr('min')));
        var bundles = parseInt(extra_books/30);
        var extra_books= parseInt(extra_books%30);
        total+=bundles*600 + extra_books*35;
        total+=(parseInt($(this).find('.kards').val())-parseInt($(this).find('.kards').attr('min')))*10;
        $(this).find('.total').html(total);
    })


}

function stripeResponseHandler(status, response) {
    // Grab the form:
    var $form = $('#payment-form');

    if (response.error) { // Problem!
        console.log(response.error);

        // Show the errors on the form:
        $form.find('#errors').html('<li>'+response.error.message+'</li>');
        $form.find('#errors').css('display','block');
        $form.find('.submit').prop('disabled', false); // Re-enable submission

        $('html,body').animate({ 
            scrollTop:$form.find('#errors').offset().top
        },200);

    } else { // Token was created!

        // Get the token ID:
        var token = response.id;

        // Insert the token ID into the form so it gets submitted to the server:
        $form.append($('<input type="hidden" name="stripeToken">').val(token));

        // Submit the form:
        $form.get(0).submit();
    }
};



